<template>
    <div style="height: 100dvh;">
        <div class="h-100 vstack" v-if="!insertFile">
            <span class="d-flex gap-3 w-100 bg-green2 p-3" v-if="attendance?._id && !(user?.operatorId && (attendance?.status === 'finished' || attendance?.status === 'abandoned')) || finished">
                <div class="rounded-pill px-4 py-2 w-100 box-clamp-1 lh-2" style="background-color: hsl(87deg 55% 65%);">
                    <span class="h-100 d-inline-flex align-items-center gap-1 me-1 text-white">
                        Protocolo: <span class="fw-bold">{{ attendance.protocol }}</span>
                    </span>
                </div>
                <b-dropdown menu-class="dropdownanexes" toggle-class="p-0 border-0 bg-transparent bg-transparent2" no-caret no-flip v-if="attendanceWindowIsOpened">
                    <template #button-content>
                        <b-icon class="panelicon" icon="paperclip" style="transform: rotate(45deg)" scale="1.4"></b-icon>
                    </template>
                    <b-dropdown-item-button class="rounded-circle mt-0 dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(291deg 55% 58%)" @click="toggleInsertFile('image/*')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Fotos">
                        <b-icon icon="image-fill"></b-icon>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(88deg 50% 56%)" @click="toggleInsertFile('.mp4')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Vídeos">
                        <b-icon icon="camera-video-fill"></b-icon>
                    </b-dropdown-item-button>
                    <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(4deg 67% 57%)" @click="toggleInsertFile('audio/*')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Áudio">
                        <b-icon icon="mic-fill"></b-icon>
                    </b-dropdown-item-button> -->
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(236deg 53% 59%)" 
                    @click="toggleInsertFile('application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow')" 
                    v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Arquivo"
                    >
                    <b-icon icon="file-earmark-fill"></b-icon>
                    </b-dropdown-item-button>
                    <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(33deg 86% 57%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Localização">
                        <b-icon icon="geo-alt-fill"></b-icon>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(199deg 94% 50%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Contato">
                        <b-icon icon="person-fill"></b-icon>
                    </b-dropdown-item-button> -->
                </b-dropdown>
            </span>
            <Whatsapp class="whatsappMsgs flex-grow-1 h-100 overflow-y-auto position-relative rounded-0" :user="user" :timestampDiff="timestampDiff" :mediaURL="mediaURL" :messages="messages" height="100%" :showLoadHistoryButton="true" />
            <div class="bg-light-purple text-secondary text-center p-4" v-if="(user?.operatorId && (attendance?.status === 'finished' || attendance?.status === 'abandoned')) || finished">
                Atendimento Finalizado!
            </div>
            <div class="vstack" v-else-if="user?.operatorId">
                <div class="d-flex align-items-center justify-content-between position-relative bg-msgInput" style="padding: 1.3em 1em" v-if="!recordedMedia.url">
                    <VEmojiPicker class="inputFileSubEmojiPicker inputMessageEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                    <b-icon class="me-2 text-secondary darkenTextOnHover" font-scale="2" icon="emoji-laughing" @click="toggleDialogEmoji"></b-icon>
                    <form class="w-100" @submit.prevent="sendMSG">
                        <b-input-group class="d-flex align-items-center bg-white rounded-pill">
                            <b-form-textarea
                            class="msgsTextarea overflow-y-hidden border-0 shadow-none rounded-pill px-5"
                            :placeholder="!attendanceWindowIsOpened ? '' : `Digite uma Mensagem...`" 
                            v-model="inputSend"
                            :disabled="!attendanceWindowIsOpened"
                            @keydown.enter.exact.prevent="sendMSG()"
                            rows="1"
                            max-rows="4"
                            no-resize
                            @input="verifyInput(inputSend)"
                            >
                            </b-form-textarea>
                            <template #append v-if="attendanceWindowIsOpened">
                                <span @click="toggleQuickAnswers" v-if="user.channelConfig?.modules?.quickAnswers && quickAnswers?.length">
                                    <b-icon class="bg-white me-3 text-secondary" font-scale="1.2" icon="lightning-charge-fill" role="button"></b-icon>
                                </span>
                            </template>
                        </b-input-group>
                    </form>
                    <span v-if="!recorder">
                        <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" font-scale="2" rotate="45" @click.prevent="sendMSG()" v-if="inputSend?.length > 0"></b-icon>
                        <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="mic-fill" font-scale="2" @click="recAudio" v-else-if="user?.operatorData && !user.operatorData.disableAudio"></b-icon>
                    </span>
                    <span class="d-flex" v-else>
                        <div class="ms-2 d-flex align-items-center">{{ recordingTime }}</div>
                        <b-icon class="ms-2 text-danger icon darkenTextOnHover" icon="stop-fill" font-scale="2" @click="confirmAudio"></b-icon>
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-between position-relative" style="background-color: hsl(251deg 49% 93%); padding: 1em" v-else>
                    <b-icon class="me-2 text-danger icon darkenTextOnHover" icon="trash-fill" font-scale="2" @click="stopAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Cancelar"></b-icon>
                    <video class="rounded audioplayer w-100" style="height:3em" controls>
                        <source :src="recordedMedia.url">
                    </video>
                    <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Enviar"></b-icon> 
                </div>
            </div>
        </div>
        <span :style="`height: calc(100dvh)`" v-else>
            <b-overlay class="d-flex flex-column h-100" :show="inputMediaOverlay" rounded="sm">
                <div class="px-2 text-white d-flex align-items-center w-100 flex-shrink-0" style="background-color: hsl(87deg 55% 65%); font-weight: 600; padding: 0.76em">
                    <b-icon class="icon" icon="x" font-scale="3" role="button" @click="toggleInsertFile()"></b-icon>
                    <div style="font-size: 1.25em">Pré-Visualizar</div>
                </div>
                <div class="insertFileBackground h-100 p-3 px-4 d-flex flex-column" style="background-color: hsl(26deg 24% 87%);">
                    <b-form class="inputFileForm h-100">
                        <div class="position-relative h-100">
                            <b-form-file
                            @input="setInputMedia"
                            class="inputFile h-100" v-bind:class="{'border-0':mediaToSend.length}"
                            :accept="fileParam"
                            drop-placeholder="SOLTAR AQUI"
                            multiple
                            >
                                <template slot="placeholder">
                                    <div>
                                        <div class="addMediaWrapper d-flex flex-column align-items-center" v-if="!mediaToSend?.length">
                                            <div><b-icon scale="3" icon="plus"></b-icon></div>
                                            <div>Adicionar</div>
                                            <div>Arquivo</div>
                                        </div>
                                    </div>
                                </template>
                                <template slot="file-name">
                                    <div class="invisible"></div>
                                </template>
                            </b-form-file>
                            <img class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='image'">
                            <video class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='video'"></video>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center top-0 start-50 translate-middle-x mh-100 mw-100" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='audio'">
                                <b-icon class="mb-3" font-scale="4" icon="file-earmark-music"></b-icon>
                                {{mediaToSend[mediaToSend.length-1].name}}
                            </div>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center" style="height: 100%; width: 50%; left:50%; transform: translateX(-50%); top: 0" v-else-if="mediaToSend?.length && (mediaToSend[mediaToSend.length-1].type=='document' || mediaToSend[mediaToSend.length-1].type=='application')">
                                <!-- <embed style="width: 100%; height: 100%" :src="mediaToSend[mediaToSend.length - 1].url" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"> -->
                                <pdf style="height: 90%" class="overflow-y-auto" :src="mediaToSend[mediaToSend.length - 1].url" :page="1" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"></pdf>
                                <span v-else>
                                    <b-icon
                                        class="mb-3" 
                                        :icon="(fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                        :class="{ 'text-success': (fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx'), 'text-danger': fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'}" 
                                        font-scale="4"
                                    ></b-icon>
                                    {{mediaToSend[mediaToSend.length-1].name}}
                                </span>
                            </div>
                        </div>
                    </b-form>
                    <div class="position-relative" v-if="currentMedia && (currentMedia.type.includes('image') || currentMedia.type.includes('video'))">
                        <b-input class="inputFileSub" placeholder="Legenda" v-model="inputSend" @input="setCaptionMedia"></b-input>
                        <b-icon class="inputFileSubEmoji darkenTextOnHover" icon="emoji-smile" @click="toggleDialogEmoji"></b-icon>
                        <VEmojiPicker class="inputFileSubEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                    </div>
                </div>
                <div class="position-relative d-flex align-items-center justify-content-center flex-shrink-0" style="background-color: hsl(251deg 49% 93%); height: 80px;">
                    <b-button class="inputFileSendBtn p-3 btn-success rounded-circle text-white" @click="sendInputMedia()"><b-icon icon="cursor-fill" rotate="45" scale="2"></b-icon></b-button>
                    <span v-if="mediaToSend?.length > 4">
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-left" style="left:4em" @click="scrollInputMediaLeft"></b-icon>
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-right" style="right:4em" @click="scrollInputMediaRight"></b-icon>
                    </span>
                    <div class="inputMediaArray d-flex align-items-center justify-content-center" style="overflow-x:hidden; width: 450px">
                        <span class="position-relative" v-for="item in mediaToSend" :key="item.id">
                            <b-icon font-scale="0.75" class="position-absolute darkenTextOnHover" style="right:1em; top:0.25em; z-index:1" icon="x-circle-fill" variant="danger" @click="removeMediaToSend(item)"></b-icon>
                            <img class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-if="item.type=='image'">
                            <video class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-else-if="item.type=='video'"></video>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='audio'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark-music" style="height: 100%; width: 100%;"></b-icon>
                            </div>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='document' || item.type=='application'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark" style="height: 100%; width: 100%;"></b-icon>
                                <div 
                                    style="position: absolute; left: 50%; bottom: 50%; font-size: 0.7em; color: #555; text-transform: uppercase; transform: translate(-65%,60%);"
                                >
                                    {{fileExt(item.name)}}
                                </div>
                            </div>
                        </span>
                        <b-form-file
                        @input="setInputMedia"
                        class="inputFile inputFileSmall"
                        :accept="fileParam"
                        drop-placeholder="SOLTAR AQUI"
                        multiple
                        v-if="mediaToSend?.length"
                        >
                            <template slot="placeholder">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"></b-icon></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                            <template slot="file-name">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"></b-icon></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                        </b-form-file>
                    </div>
                </div>
            </b-overlay>
        </span>
        <b-modal id="modal-tabulations" ref="modal-tabulations" header-class="py-0" hide-header hide-footer v-if="attendance">
            <div class="text-secondary h6 m-0 mb-3">
                Tabulação:
            </div>
            <b-form-select class="w-100 p-1 rounded border-grey" v-model="attendance.completionReason">
                <option :value="item.text" v-for="item in tabulations" :key="item.id">{{ item.text }}</option>
            </b-form-select>
            <div class="mt-3">
                <b-button class="btn-success me-2" @click="finishAttendance(attendance,1)">Finalizar Atendimento</b-button>
                <b-button class="btn-danger" @click="$bvModal.hide('modal-tabulations')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js"></script>
<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js"></script>


<script>
import Whatsapp from './whatsapp.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import api from '../services/apiService.js'

const workerOptions = {
    encoderWorkerFactory: function () {
        return new Worker(`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`)
    },
    OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
    WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
};

export default {
    components: {
        Whatsapp,
        VEmojiPicker
    },
    props: [
        'timestampDiff',
        'mediaURL',
        'user',
        'socket',
        'i18n'
    ],
    created() {
        const scripts = [
            "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js",
            `${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`
        ]
        scripts.forEach(script => {
            const tag = document.createElement("script")
            tag.setAttribute("src", script)
            document.head.appendChild(tag)
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.init()
        })
    },
    data() {
        return {
            messages: [],
            inputSend: '',
            mediaToSend: [],
            recorder: false,
            chunks: [],
            recordInterval: '',
            recordingTime: '00:00',
            recordedMedia: {url:null,file:null},
            sendingAudio: false,
            selectedMedia: '',
            currentMedia: null,
            inputMediaOverlay: false,
            attendanceWindowIsOpened: true,
            showEmojiDialog: false,
            selectedItemEmoji: null,
            loadingQuickAnswers: false,
            quickAnswers: [],
            showQuickAnswers: false,
            attendance: null,
            replyMessage: {},
            mediaRecorder: null,
            finished: false,
            tabulations: [],
            insertFile: false,
            mediaToSend: [],
            file: [],
            fileParam: null,
            clientProtocols: [],
            protocolsCount: 1,
        }
    },
    methods: {
        init() {
            if(this.socket?.active || (this.user && !this.user.operatorId)) {
                this.getQuickAnswers()
                this.getAttendance()
                if(this.user.operatorId)
                    this.socketOnEvents()
                this.rootEvents()
            } else {
                setTimeout(() => {
                    this.init()
                }, 1000);
            }
        },
        async getAttendance() {
            const attendanceResp = await api.getAttendanceById(this.user.attendanceId)

            if (attendanceResp.statusCode == 200) {
                this.user.attendanceId = attendanceResp.attendance._id
            }

            const resp = await api.getProtocolMessages(this.user.attendanceId)
            if (resp.statusCode == 200) {
                const { _id, messages } = resp.messages
                if (attendanceResp.statusCode == 200) {
                    const attendance = attendanceResp.attendance
                    this.attendance = attendance
                    this.attendance.modelId = _id
                    this.messages = messages

                    this.attendanceWindowIsOpened = await this.checkAttendanceWindowIsOpened(attendance)

                    console.log({attendance }, this.attendanceWindowIsOpened)

                    return
                }
            } 

            this.$emit("msg", { success: false, text: "Ocorreu um erro!"})
        },
        selectEmoji(emoji) {
            this.inputSend += emoji.data
            if(this.currentMedia)
                this.currentMedia.caption += emoji.data
            this.toggleDialogEmoji()
        },
        async selectReactEmoji(emoji) {
            const msg = this.selectedItemEmoji
            this.toggleDialogReactEmoji(this.selectedItemEmoji)
            const channel = this.user.operatorData.channel
            if(!channel) {
                const channelResp = await api.getChannel(this.user.channelId || this.user.roleId)
                if(channelResp.statusCode == 200)
                    channel = channelResp.channel.channel
            }
            if(channel) {
                const obj = {
                    modelId: this.attendance.modelId,
                    idWpp: msg.idWpp,
                    from: channel.channelNumber,
                    emoji: emoji.data,
                    to: this.attendance.clientNumber,
                    body: msg.body,
                    name: this.user.name
                }
                this.socket.emit('send_message_reaction', obj)
            }
        },
        toggleDialogEmoji() {
            if(this.attendanceWindowIsOpened)
                this.showEmojiDialog = !this.showEmojiDialog
        },
        toggleDialogReactEmoji(item) {
            if(this.attendanceWindowIsOpened) {
                if(!item.showReactEmojiDialog) item.showReactEmojiDialog = false
                item.showReactEmojiDialog = !item.showReactEmojiDialog
                this.$set(item,"body",item.body+"a")
                this.$set(item,"body",item.body.slice(0,-1))
                this.selectedItemEmoji = item
                setTimeout(() => {
                    if(this.$refs.inputReactEmojiPicker) {
                        this.$refs.inputReactEmojiPicker.focus()
                    }
                }, 500);
            }
        },
        async recAudio() {
            if(this.attendance._id && this.attendanceWindowIsOpened) {
                // if(this.browser!='Firefox') {
                //     let msg = {
                //         text: "Este recurso só está disponível no navegador Firefox!",
                //         success: false
                //     }
                //     return this.$emit("msg",msg)
                // }
                window.MediaRecorder = OpusMediaRecorder
                
                navigator.mediaDevices.getUserMedia({ audio:true }).then(stream=> {
                    this.recorder = true
                    let totalSeconds = 0
                    let seconds = 0
                    let minutes = 0
                    this.recordInterval = setInterval(() => {
                        ++totalSeconds
                        seconds = pad(totalSeconds % 60)
                        minutes = pad(parseInt(totalSeconds / 60))
                        this.recordingTime = minutes+':'+seconds
                    }, 1000)
                    function pad(val) {
                        const valString = val + ""
                        if (valString.length < 2) {
                            return "0" + valString
                        } else {
                            return valString
                        }
                    }
                    this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg; codecs=opus' }, workerOptions)
                    this.chunks = []
                    this.mediaRecorder.ondataavailable = e => {
                        this.chunks.push(e.data)
                    }
                    this.mediaRecorder.onerror = (e) => {
                        console.log('MediaRecorder error:', e)
                    }
                    this.mediaRecorder.start()
                })
                .catch(function(err) {
                    console.log('record',err)
                })
            }
        },
        confirmAudio() {
            clearInterval(this.recordInterval)
            this.recordingTime = ''

            this.mediaRecorder.stop()
            this.mediaRecorder.stream.getTracks().forEach(i => i.stop())

            this.recorder = false

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.chunks, { 'type': 'audio/ogg; codecs=opus' })
                const file = new File([blob], "name.mp3",{ type: blob.type })
                this.recordedMedia.file = file

                const reader = new FileReader()
                reader.readAsDataURL(blob)
                
                reader.onloadend = () => {
                    const dataUrl = reader.result
                    console.log({dataUrl})
                    this.recordedMedia.url = dataUrl 
                }
            }
        },
        playAudio(url) {
            const audio = new Audio(url)
            audio.play()
        },
        stopAudio() {
            this.recordedMedia = { url:null, file:null }
        },
        async sendAudio() {
            if(this.sendingAudio) return
            this.sendingAudio = true

            try {
                const formData = new FormData()
                this.recordedMedia.file.agent = window.navigator.vendor
                console.log('file',this.recordedMedia.file)
                formData.append('file', this.recordedMedia.file)
                const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile':'audio', 'clientNumber':this.attendance.clientNumber, 'from':'enviadas'}
                const uploadResp = await api.upload(formData,headers)
                // console.log('uploadResp',uploadResp)
                // let filepath = resp.data.files['audio'].filepath.split('upload-files/')[1]
                const url = uploadResp.file.uploadDir
                const obj = {
                    attendanceId: this.attendance._id, 
                    url, 
                    type: 'audio',
                    caption: '', 
                    filename: `audio${Date.now()}`,
                    clientNumber: this.attendance.clientNumber,
                    channelId: this.attendance.channel || this.attendance.channelId,
                    groupId: this.attendance._id,
                    operatorId: this.user.operatorData._id,
                    name: this.user.operatorData.name
                }
                // if(this.socket.active) {
                    this.socket.emit('send_message',obj)
                // } 
                // else {
                    // const a = []
                    // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                    //     a = JSON.parse(localStorage.getItem('msgs'))
                    // a.push(obj)
                    // localStorage.setItem('msgs', JSON.stringify(a))
                // }
                const msg = {
                    body: '',
                    fromMe: true,
                    timestamp: Date.now()/1000,
                    type: 'audio',
                    mediaURL: this.recordedMedia.url
                }
                this.messages.push(msg)   
                this.recordedMedia = {url:null,file:null}
                this.scrollBottomMsgsDiv()
                this.sendingAudio = false
            } catch(error) {
                console.error(error)
                this.sendingAudio = false
            }
        },
        async sendMSG(text = null) {
            const hasClientSentMsg = this.messages.find(el => el.fromMe === false)

            if(this.messages.length && !hasClientSentMsg) {
                const channelType = this.attendance.channelType || await this.getChannelType(this.attendance.channel)
                if(channelType === 'gupshup' || channelType === 'cloud') {
                    let msg = {
                        text: "O contato não respondeu a mensagem HSM, não é possível enviar nova mensagem",
                        success: false
                    }
                    return this.$emit("msg", msg)
                }
            }

            if(!text) {
                if(this.inputSend.startsWith('/')) {
                    let quickAnswer = null
                    if(this.quickAnswers?.length) {
                        quickAnswer = this.quickAnswers.find(el => el.shortcuts.includes(this.inputSend.substring(1)))
                        if(quickAnswer)
                            text = quickAnswer.content
                        else
                            text = this.inputSend
                    } else
                        text = this.inputSend
                } else
                    text = this.inputSend
            }

            if(text?.length > 0) {
                console.log(this.attendance)
                const quotedMsg = Object.keys(this.replyMessage).length === 0 ? undefined : this.replyMessage
                const obj = {
                    attendanceId: this.attendance._id,
                    msg: text,
                    type: 'text',
                    clientNumber: this.attendance.clientNumber,
                    channelId: this.attendance.channel || this.attendance.channelId,
                    operatorId: this.user.operatorData._id,
                    quotedMsg,
                    name: this.user.operatorData.name // Nome do operador caso seja grupo
                }
                // if(this.socket.active)
                        this.socket.emit('send_message',obj)
                // else {
                    // let a = []
                    // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                    //     a = JSON.parse(localStorage.getItem('msgs'))
                    // a.push(obj)
                    // localStorage.setItem('msgs', JSON.stringify(a))
                // }
                // console.log(JSON.parse(localStorage.getItem('msgs')))
                const date = new Date(Date.now())
                const timestamp = (date.getTime() + date.getTimezoneOffset()) / 1000

                const msg = {
                    body: text,
                    fromMe: true,
                    timestamp,
                    statusAck: 0,
                    type: 'text',
                    quotedMsg
                }
                this.messages.push(msg)

                if(this.replyMessage)
                    this.replyMessage = {}
                // console.log('msg',msg)
                this.inputSend = ''
                this.scrollBottomMsgsDiv()
            }
            this.hideQuickAnswersDiv()
        },
        async sendMsg2(attendance, message) {
            if(attendance) {
                // console.log({ attendance,message })

                const operatorNickname = `*${this.user.operatorData.nickname || this.user.operatorData.name}*: `
                if(message.body?.startsWith(operatorNickname)) {
                    message.body = message.body.replace(operatorNickname,'')
                }

                message.quotedMsg = null
                const obj = {
                    attendanceId: attendance._id,
                    msg: message.body,
                    clientNumber: attendance.clientNumber,
                    channelId: attendance.channel || attendance.channelId,
                    groupId: attendance._id,
                    operatorId: this.user.operatorData._id,
                    name: this.user.operatorData.name, // Nome do operador caso seja grupo
                    url: message.mediaURL,
                    ...message
                }
    
                this.socket.emit('send_message',obj)
            } else {
                console.error("attendance is missing!")
            }
        },
        setCaptionMedia(e) {
            this.currentMedia.caption = e
        },
        async sendInputMedia() {
            if (!this.attendance) return

            if(this.file?.length > 0) {
                let count = 0
                this.inputMediaOverlay = true
                await this.file.forEach(async (file) => {
                    const formData = new FormData();
                    let message = { body:'', mediaUrl:'' }
                    if(file.caption)
                        message.body = file.caption
                    let typefile = file['type'].split('/')[0]
                    if(typefile == 'text')
                        typefile = 'file'
                    // if(type=='application') {
                    //     type = 'document'
                    // }
                    const media = this.mediaToSend.find(el => el['name'] == file.name)
                    const ext = file.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    // console.log('ext',ext)
                    // console.log(e)
                    const msg = {
                        body: file.caption,
                        fromMe: true,
                        timestamp: Date.now()/1000,
                        type: media.type,
                        mediaURL: media.url,
                        caption: message.body,
                        filename: file.name,
                        ext
                    }
                    file.filename = file.name
                    formData.append('file',file)
                    const headers = { 'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'clientNumber': this.attendance.clientNumber }
                    const uploadResp = await api.upload(formData,headers)
                    console.log('uploadResp',uploadResp)
                    
                    if(uploadResp.statusCode != 200) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false
                        await this.toggleInsertFile()
                        return this.$emit("msg", {
                            text: "Ocorreu um erro! Tente novamente mais tarde ou relogue!",
                            success: false,
                            countdown: 8
                        })
                    }

                    msg.mediaURL = uploadResp.file.uploadDir

                    this.messages.push(msg)

                    const url = uploadResp.file.uploadDir
                    // console.log(message)
                    const obj = {
                        attendanceId: this.attendance._id, 
                        url, 
                        type: typefile, 
                        caption: message.body, 
                        filename: file['name'].split('.')[0],
                        clientNumber: this.attendance.clientNumber,
                        channelId: this.attendance.channel || this.attendance.channelId,
                        operatorId: this.user.operatorData._id,
                        name: this.user.operatorData.name
                    }
                    // if(this.socket.active) {
                        this.socket.emit('send_message',obj)
                    // } else {
                        // let a = []
                        // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                        //     a = JSON.parse(localStorage.getItem('msgs'));
                        // a.push(obj)
                        // localStorage.setItem('msgs', JSON.stringify(a));
                    // }
                    count++
                    if(count == this.file.length) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false
                        await this.toggleInsertFile()
                        this.scrollBottomMsgsDiv()
                    }
                })
            }
        },
        scrollBottomMsgsDiv() {
            const msgsdiv = document.querySelector('.waBg')
            if (msgsdiv) {
                setTimeout(() => {
                    msgsdiv.scrollTo(0, msgsdiv.scrollHeight)
                }, 500);
            }
        },
        scrollTopMsgsDiv() {
            const msgsdiv = document.querySelector('.waBg')
            if (msgsdiv) {
                msgsdiv.scrollTo(0, 0);
            }
        },
        async verifyInput(input) {
            if(input == "/" || input.startsWith("/")) {
                await this.getQuickAnswers(input)
                if(this.quickAnswers?.length) {
                    this.showQuickAnswersDiv()
                } else {
                    this.hideQuickAnswersDiv()
                }
            } else {
                this.hideQuickAnswersDiv()
            }
        },
        async getQuickAnswers(shortcut = null) {
            if(!this.user.operatorData?.department?.length) return
            if(!this.socket?.connected) {
                return setTimeout(() => {
                    this.getQuickAnswers(shortcut)
                }, 3000);
            }
            this.loadingQuickAnswers = true
            const form = {
                departmentsArray: this.user.operatorData.department,
            }
    
            // if(this.attendance?.departmentId)
            //     form.departmentsArray = [ this.attendance?.departmentId ]
            if(shortcut) {
                shortcut = shortcut.slice(1)
                form.shortcut = shortcut
            }
    
            const resp = await api.getQuickAnswersByDepartments(this.user.operatorData.channelId,form)
            // console.log('answers resp',resp)
            this.loadingAnswers = false
            if(resp.statusCode != 200)  {
                return this.quickAnswers = []
            }
            this.quickAnswers = resp.quickAnswers.filter(el => el.channelId == this.user.operatorData.channelId)
        },
        sendQuickAnswer(msg) {
            this.sendMSG(msg)
            this.hideQuickAnswersDiv()
        },
        toggleQuickAnswers() {
            // if(this.quickAnswers.length)
            this.showQuickAnswers = !this.showQuickAnswers
        },
        showQuickAnswersDiv() {
            if(!this.showQuickAnswers)
                this.showQuickAnswers = true
        },
        hideQuickAnswersDiv() {
            if(this.showQuickAnswers)
                this.showQuickAnswers = false
        },
        async getChannelType(channelId) {
            const channelResp = await api.getChannel(channelId)
            if(channelResp?.statusCode == 200) {
                return channelResp.channel.channel.apiType
            }
            return null
        },
        async loadMessages() {
            const client = this.attendance
            const resp = await api.loadMessages({
                attendance: {
                    _id: client._id || client.attendanceId,
                    clientNumber: client.clientNumber,
                    channelId: client.channelId,
                },
                operatorId: this.user.operatorId,
            });

            // console.log("resp",resp)

            if (resp.statusCode !== 200) {
                this.$emit('msg', {
                    text: 'Ocorreu um erro ao carregar as mensagens!',
                    success: false,
                })
                return
            }

            const {
                messages: { messages, _id },
                attendance
            } = resp

            let msgs = []
            let dateAux = new Date(1)
            const [year, month, day] = [
                dateAux.getFullYear(),
                (dateAux.getMonth() + 1).toString().padStart(2, '0'),
                dateAux.getDate().toString().padStart(2, '0'),
            ]
            dateAux = `${month}-${day}-${year}`

            for (const el of messages) {
                if (this.timestampDiff > 100) el.timestamp -= this.timestampDiff;

                if (el.timestamp > 9999999999) el.timestamp /= 1000;

                let date = new Date(el.timestamp * 1000)
                const [year, month, day] = [
                    date.getFullYear(),
                    (date.getMonth() + 1).toString().padStart(2, '0'),
                    date.getDate().toString().padStart(2, '0'),
                ];
                date = `${month}-${day}-${year}`

                if (new Date(date) > new Date(dateAux)) {
                    const d = { type: 'date', timestamp: el.timestamp - 1, date };
                    msgs.push(d);
                    dateAux = date;
                }

                if (el.type === 'vcard') {
                    if (!el.vcardName)
                        el.vcardName = el.body.split('FN:')[1].split('\n')[0];
                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0];
                } else if(el.type === 'product_list') {
                    const product = await this.getProductByProductId(el.body?.productList?.at(0)?.productId)
                    if(product) {
                        el.body.imageURL = product.imageLink
                    }
                }

                msgs.push(el)
            }
            
            this.messages = msgs

            client.modelId = _id

            dateAux = null
            msgs = null
            this.scrollBottomMsgsDiv()
        },
        showNotification(messageText,contactName,contactPhotoURL) {
            if(document.visibilityState === "visible")
                return

            var notification = new Notification(contactName, { body: messageText, icon: contactPhotoURL || "https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" })
            notification.onclick = () => { 
                notification.close()
                window.parent.focus()
            }
        },
        requestNotificationPermission() {
            if (!("Notification" in window))
                alert("Esse navegador não suporta notificações!");
            if(Notification.permission == "denied") {
                alert("Notificações estão bloqueadas pelo seu navegador! \nVocê pode desbloqueá-la nas configurações do seu navegador.")
            }
            Notification.requestPermission((permission) => {
               this.browserNotification = permission
            });
        },
        requestAndShowPermission() {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") 
                    this.showNotification();
            });
        },
        sendBrowserNotification(data) {
            if ('Notification' in window) {
                // console.log('notification data',data)
                let message = ''
                switch(data.messages?.type) {
                    case 'text':
                    case 'chat':
                        message = data.messages?.body
                    break;
                    case 'video':
                        message = "Vídeo"
                    break;
                    case 'image':
                        message = "Ímagem"
                    break;
                    case 'audio':
                        message = "Áudio"
                    break;
                    case 'location':
                        message = "Localização"
                    break;
                    case 'vcard':
                        message = "Contato"
                    break;
                    case 'document':
                    case 'application':
                        message = "Documento"
                    break;
                    case 'sticker':
                        message = "Sticker"
                    break;
                    case 'button_reply':
                        message = data.messages.body.button_reply?.title || data.messages.body.title
                    break;
                    case 'list_reply':
                        message = data.messages.body.list_reply?.title || data.messages.body.title
                    break;
                    default:
                        return
                }
    
                const permission = Notification.permission

                if(permission === "granted")
                    this.showNotification(message,data.attendance?.clientName || data.messages?.name, data.attendance?.photoURL)
                else if(permission === "default")
                    this.requestAndShowPermission()
            }
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200)
                this.tabulations = resp.tabulations.sort((a, b) => a.text.localeCompare(b.text))
        },
        async finishAttendance(attendance, fromModal=false) {
            if(!fromModal) {
                await this.getTabulations()
                if(this.tabulations?.length > 0)
                    return this.$bvModal.show('modal-tabulations')
                this.socket.emit('finish_attendance',{ attendance, reason: attendance.completionReason })
                this.attendance = {}
            } else {
                if(!attendance.completionReason) {
                    return this.$emit("msg", {
                        text: 'Tabulação não selecionada!',
                        success: false
                    })
                }
                // console.log('attendance',attendance)
                this.$refs['modal-tabulations'].hide()
                this.attendance = {}
                this.socket.emit('finish_attendance',{ attendance, reason:attendance.completionReason })
            }
        },
        async checkAttendanceWindowIsOpened(attendance) {
            if(attendance?._id) {
                if(!attendance.channelType) {
                    const channelType = await this.getChannelType(this.user.channelId)
                    attendance.channelType = channelType
                }
                if(attendance.channelType !== 'gupshup' && attendance.channelType !== 'cloud') return true
    
                if(!attendance.lastMessageContact) return false
    
                const date = new Date(attendance.lastMessageContact)
                const currentDate = new Date()
    
                // Calculate the difference in milliseconds between the current date/time and the given date/time
                const timeDifference = currentDate.getTime() - date.getTime();
    
                // Calculate the number of hours in the time difference
                const hoursDifference = timeDifference / (1000 * 60 * 60);
    
                if(hoursDifference > 24) return false
                return true
            }
        },
        toggleInsertFile(param=null) {
            this.insertFile = !this.insertFile
            this.mediaToSend = []
            this.file = []
            this.inputSend = ''
            this.currentMedia = null
            if(param)
                this.fileParam = param
        },
        setInputMedia(arrayMedia) {
            if(arrayMedia?.length) {
                arrayMedia.forEach(e => {
                    console.log('file',e)

                    e = new File([e], `${e.name.replace(/\.jfif$/, '.jpeg')}`, {
                        type: e.type 
                    })

                    if (e.size > 15000000) {
                        const input = document.querySelector('.inputFileForm')
                        input.reset()
                        return this.$emit("msg", {
                            text: "O tamanho máximo permitido para envios é de 15 MB",
                            success: false
                        })
                    }
                    if(e.type == 'video/x-matroska' || e.type == 'image/webp' || !e.type) {
                        const input = document.querySelector('.inputFileForm')
                        input.reset()
                        return this.$emit("msg", {
                            text: 'Extensão não suportada!',
                            success: false
                        })
                    }               

                    const ext = e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    if(this.containsUppercase(ext)) {
                        e = new File([e],e.name.replace(ext,ext.toLowerCase()), { type: e.type })
                    }

                    this.inputSend = ''
                    this.currentMedia = e
                    // this.file = []
                    this.file.push(e)
                    const reader = new FileReader();
                    reader.readAsDataURL(e)
                    reader.onload = () => {
                        const dataURL = reader.result;
                        // this.mediaToSend = []
                        const type = e['type'].split('/')[0]
                        if(type == "text") {
                            type = "document"
                        }
                        this.mediaToSend.push({url:dataURL.replace('base64','charset=utf-8;base64'),type,name:e['name'].replace('.jfif','.jpeg')})
                    };
                });
            }
        },
        containsUppercase(str) {
            return /^[A-Z]+$/.test(str);
        },
        fileExt(filename) {
            if(filename && filename.split('').reverse().join('')) {
                const ext = filename.split('').reverse().join('').split('.')[0].split('').reverse().join('');
                return ext
            }
            return ''
        },
        async loadProtocols() {
            const resp = await api.getContactHistory(this.user.operatorData.channelId, this.attendance.clientNumber)
            // console.log('respHistory',resp)
            if(resp.statusCode == 200) {
                const data = resp.result.attendances
                this.clientProtocols = data.sort((a,b) => (a.attendanceStartTime < b.attendanceStartTime) ? -1 : ((b.attendanceStartTime < a.attendanceStartTime) ? 1 : 0))
                // console.log('this.clientProtocols',this.clientProtocols)
                const currentProtocol = this.clientProtocols.findIndex(el => el.protocol == this.attendance.protocol)
                if(currentProtocol > -1) {
                    this.clientProtocols.splice(currentProtocol,1)
                }
                // console.log("this.protocolsCount",this.protocolsCount)
                const protocol = this.clientProtocols[this.clientProtocols.length - this.protocolsCount]
                const timestamp = (new Date(this.attendance.attendanceStartTime).getTime() / 1000) -7
                const createdAt = new Date(this.attendance.attendanceStartTime)
                const currentProtocolStart = { type: 'protocolStart', createdAt, timestamp, protocol: this.attendance.protocol }

                if(protocol?.protocol) {
                    if(this.protocolsCount == 1)
                        this.messages.unshift(currentProtocolStart)
                    const respProtocolMessages = await api.getProtocolMessages(protocol._id)
                    // console.log('respProtocolMessages',respProtocolMessages)
                    if(respProtocolMessages.statusCode == 200) {
                        const timestamp = (new Date(protocol.attendanceStartTime).getTime() / 1000) -7
                        const createdAt = new Date(protocol.attendanceStartTime)
                        
                        const protocolStart = { type: 'protocolStart', createdAt, timestamp, protocol: protocol.protocol }

                        let dateAux

                        respProtocolMessages.messages.messages.reverse().forEach(el => {
                            if(this.timestampDiff > 100)
                                el.timestamp -= this.timestampDiff

                            if(el.timestamp > 9999999999)
                                el.timestamp /= 1000

                            if(!dateAux) {
                                dateAux = new Date(el.timestamp*1000)
                                const year = dateAux.getFullYear();
                                const month = (dateAux.getMonth() + 1).toString().padStart(2, '0')
                                const day = dateAux.getDate().toString().padStart(2, '0')
                                dateAux = `${month}-${day}-${year}`
                            }

                            let date = new Date(el.timestamp*1000)
                            const year = date.getFullYear();
                            const month = (date.getMonth() + 1).toString().padStart(2, '0')
                            const day = date.getDate().toString().padStart(2, '0')
                            date = `${month}-${day}-${year}`
                            if(new Date(date) < new Date(dateAux)) {
                                const d = { type: 'date', timestamp: el.timestamp-1, date: dateAux } // timestamp here is wrong, as the dateAux comes from the previous message. if necessary, a fix would be to set a timestampAux to keep the timestamp from previous message
                                this.messages.unshift(d)
                                dateAux = date
                            }

                            if(el.type=="vcard") {
                                try{
                                    if(!el.vcardName)
                                        el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                                        if(!el.vcardNumber)
                                        el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                                }
                                catch(err) {
                                    console.error('vcard err',err)
                                }
                            }
                            this.messages.unshift(el)
                        })
                        this.messages.unshift(protocolStart)
                        this.scrollTopMsgsDiv()
                    }
                    this.protocolsCount++
                } else {
                    this.$emit("msg", {
                        text: "Não foi encontrado um protocolo anterior!",
                        success: false
                    })
                }
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao carregar protocolos!",
                    success: false
                })
            }
            this.$root.$emit('loadedProtocolMessages', true)
        },
        rootEvents() {
            this.$root.$on('toggleDialogReactEmoji', (item) => {
                this.toggleDialogReactEmoji(item)
            })

            this.$root.$on('selectReactEmoji', (emoji) => {
                this.selectReactEmoji(emoji)
            })

            this.$root.$on('loadProtocolMessages', () => {
                this.loadProtocols()
            })
        },
        socketOnEvents() {
            this.socket.on('message_ack', async (data) => {
                console.log('message_ack',data)
                if(!this.attendance || !this.messages || !this.messages.length) return
                switch(data.statusAck) {
                    case 1:
                    case '1': {
                        // let messageIndex = this.messages.findIndex(el => el.fromMe && !el.statusAck && !el.idWpp)
                        let messageIndex = this.messages.findLastIndex(el => el.fromMe && el.statusAck && el.idWpp)
                        messageIndex = this.messages.findIndex((el,i) => el.fromMe && !el.statusAck && !el.idWpp && i > messageIndex)
                        // console.log('messageIndex',messageIndex)
                        if(messageIndex == -1) {
                            return await this.loadMessages()
                        }

                        // this.messages[messageIndex].statusAck = data.statusAck
                        await this.$set(this.messages[messageIndex], 'statusAck', data.statusAck)
                        this.messages[messageIndex].idWpp = data.idWpp
                        break
                    }
                    default: {
                        const message = this.messages.find(e => e.idWpp == data.idWpp)
                        // console.log('message',message)
                        if(message)
                            await this.$set(message, 'statusAck', data.statusAck)
                        else {
                            return await this.loadMessages()
                        }
                        break
                    }
                }
            })

            this.socket.on('new_message',async (data) => {
                console.log('new_message',data.messages)
                if(data.messages.type == "vcard") {
                    if(!data.messages.vcardName)
                        data.messages.vcardName = data.messages.body.split('FN:')[1].split('\n')[0]
                    if(!data.messages.vcardNumber)
                        data.messages.vcardNumber = data.messages.body.split('waid=')[1].split(':')[0]
                }

                if(!data.messages.fromMe)
                    this.sendBrowserNotification(data)

                if(this.attendance._id == data.attendance._id) {
                    if(this.messages) {
                        if(typeof data.messages.timestamp == 'string') data.messages.timestamp = parseInt(data.messages.timestamp)

                        this.messages.push(data.messages)
                        if(!this.attendanceWindowIsOpened)
                            this.attendanceWindowIsOpened = true
                    } else {
                        chat.lastMsg = data.messages
                    }
                } 
                // else {
                //     return console.log('chat não encontrado')
                // }

                if(!data.messages.fromMe) {
                    this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
                }
            })

            this.socket.on('updated_attendance',(attendance) => {
                console.log('updated_attendance',attendance)

                if(attendance._id == this.attendance._id) {
                    this.attendance = attendance
                }
            })

            this.socket.on('update_avatar',(attendance) => {
                console.log('update_avatar',attendance)
                if(attendance._id == this.attendance._id) {
                    this.attendance = attendance
                }
            })

            this.socket.on('load_messages',async (data) => {
                const msgs = []
                console.log('load_messages',data)
                await data.messages.forEach(el => {
                    if(el.type=="vcard") {
                        try{
                            if(!el.vcardName)
                                el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                            if(!el.vcardNumber)
                                el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                        }
                        catch(err) {
                            console.error('vcard err',err)
                        }
                    }
                    msgs.push(el)
                })
                await this.$set(this.attendance, 'msgs', msgs)
                // this.scrollBottomMsgsDiv()
                // this.scrollBottomMsgsDiv()
            })

            this.socket.on('online_operators',(data) => {
                if(data?.length)
                    this.onlineOperators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            })

            this.socket.on('get_departments',(data) => {
                this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            })

            // this.socket.on('load_protocol_messages',async (data) => {
            //     console.log('load_protocol_messages',data)
            //     const timestamp = (new Date(data.messages[0].timestamp).getTime())-7
            //     const createdAt = new Date(data.messages[0].timestamp*1000)
            //     const protocolStart = {type:'protocolStart',createdAt,timestamp}

            //     await data.messages.reverse().forEach(el=>{
            //         this.messages.unshift(el)
            //     })
            //     this.messages.unshift(protocolStart)
            //     this.loadingProtocol = false
            // })

            this.socket.on('finish_attendance',async (data) => {
                console.log('finish_attendance',data)

                this.$emit("msg", { success: true, text: "Atendimento finalizado!" })

                this.finished = true

                if(this.attendance._id == data._id) {
                    this.attendance = {}
                    setTimeout(() => {
                        window.close()
                    }, 1000);
                }
            })

            this.socket.on('work_time_over', () => {
                console.log('work_time_over')
                this.user.operatorData.status = false
                this.workTimeOver = true
                this.$root.$emit('workTimeOver',this.workTimeOver)
                this.$refs['modal-workTimeOver'].show()
            })

            this.socket.on('existing_contact',(data) => {
                console.log('existing_contact',data)

                if(this.$refs["modalPickDepartmentNotification"])
                    this.$refs["modalPickDepartmentNotification"].hide()

                if(this.$refs["modal-activecomm"])
                    this.$refs["modal-activecomm"].hide()

                this.$emit("msg", {
                    text: 'Este contato já está inserido na plataforma!',
                    success: false
                })
            })

            this.socket.on('send_notification',(data) => {
                console.log('send_notification',data)
                var msg = null

                if(this.$refs["modalPickDepartmentNotification"])
                    this.$refs["modalPickDepartmentNotification"].hide()

                if(data.statusCode == 408) {
                    msg = {
                        text: "Seu horário de atendimento foi finalizado!",
                        success: false
                    }
                } else {
                    switch(data.status) {
                        case 'sended':
                            if(this.user.operatorData.channel?.apiType != 'business') {
                                this.selectedTemplate.bodyId = undefined
    
                                if(this.$refs['modal-activecomm'])
                                    this.$refs['modal-activecomm'].hide()
    
                                msg = {
                                    text: 'Comunicação inserida na fila com sucesso!',
                                    success: true,
                                }
                            }
                            break
                        case 'invalid_number':
                            msg = {
                                text: 'Número Inválido!',
                                success: false,
                                countdown: 10
                            }
                            break
                        case 'unsent_message':
                        case 'api_error':
                            msg = {
                                text: 'Não foi possível identificar uma conta de WhatsApp neste número',
                                success: false,
                                countdown: 10
                            }
                            break
                        case 'channel_not_found':
                            msg = {
                                text: 'Ocorreu um erro ao enviar a comunicação ativa!',
                                success: false,
                                countdown: 10
                            }
                            break
                        // default:
                        //     msg = {
                        //         text: 'Ocorreu um erro ao enviar a comunicação ativa!',
                        //         success: false,
                        //         countdown: 10
                        //     }
                        //     break
                    }
                }

                if(msg)
                    this.$emit("msg", msg)
                //invalid_number,  channel_not_found,  sended, unsent_message
            })

            this.socket.on('api_error',(data) => {
                console.log('api_error', data)
                if(data.attendanceId) {
                    if(this.$refs['modal-activecomm']) {
                        const msg = {
                            text: 'Não foi possível identificar uma conta de WhatsApp neste número',
                            success: false,
                            countdown: 10
                        }
                        this.$emit("msg", msg)
                    }
                }
            })

            this.socket.on('valid_contact',(data) => {
                console.log('valid_contact', data)

                if (this.attendance.clientNumber === data.data?.phone && attendance.clientNumber != data.contactExist.user)
                    attendance.clientNumber = data.contactExist.user
            })

            this.socket.on('message_revoke_everyone', (data) => {
                console.log('message_revoke_everyone',data)
            })

            this.socket.on('message_revoke_me', (data) => {
                console.log('message_revoke_me',data)
            })

            this.socket.on('message_react', (data) => {
                console.log('message_react',data)
                const msg = this.messages?.find(el => el.idWpp == data.idWpp)
                if(msg) {
                    if(!msg.react)
                        msg.react = [ data.react ]
                    else {
                        const index = msg.react.findIndex(el => el.contactId == data.react.contactId)
                        if(index != -1)
                            msg.react[index] = data.react
                        else
                            msg.react.push(data.react)
                    }
                    msg.timestamp++
                    msg.timestamp--
                }
            })

            this.socket.on('has_secondary_channels', (data) => {
                // console.log("has_secondary_channels",data)
                this.user.operatorData.channel.hasSecondaryChannels = data
                this.getSecondaryChannels()
            })

            this.socket.on('attendance_picked', (attendanceId) => {
                if(this.attendance._id == attendanceId) {
                    this.attendance = {}
                }
            })

            this.socket.on('adm_operator_logout', () => {
                console.log('adm_operator_logout')
                logout()
            })
        }
    },
}
</script>

<style>
    .inputFileSub{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid hsl(245deg 12% 63%) !important;
        border-radius: 0;
    }
    .inputFileSubEmoji{
        position: absolute;
        right: 0;
        top: 1em;
    }
    .inputFileSubEmojiPicker{
        position: absolute;
        top: -25em;
        right: 1em;
        z-index: 1;
        flex-direction: column-reverse !important;
    }
    .inputMessageEmojiPicker{
        right: unset;
        top: -26em;
        left: 0;
    }
    .whatsappMsgs > .rounded {
        border-radius: 0 !important;
    }
    .dropdownanexes{
        background-color: transparent !important;
        border-color: transparent !important;
        flex-direction: column;
        min-width: fit-content;
        cursor: default;
        margin-left: -0.3em;
        z-index: 2;
    }
    .dropdownanexes .b-icon{
        color: #fff;
        font-size: 1.5em !important;
    }
    .dropdownanexes svg{
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    .dropdownanexesitem{
        margin: 15px 0;
        margin-left: -12px;
        box-shadow: 1.4px 2px 5px #555 !important;
    }
    .dropdownanexesitem:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .dropdownanexesitembtn{
        padding: 12px;
        padding-bottom: 8px;
        width: fit-content;
        background-color: transparent !important;
    }
    .mediaTabContent{
        margin: auto -1rem;
    }
    .inputFileSmall{
        height: 65px !important;
        width: 65px;
    }
    .inputFileSmall .custom-file-label{
        /* height: 65px !important; */
        border: none;
        font-size: 0.5em;
        background-color: white !important;
        width: 65px;
        margin-bottom: 1em;
    }
    .inputFile input, .inputFile .custom-file-label::after{
        display: none;
    }
    .inputFile.border-0 .custom-file-label{
        border: none;
    }
    .inputFile .custom-file-label{
        background-color: transparent;
        /* height: 250px; */
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: hsl(246deg 15% 41%);
        border-color: hsl(246deg 29% 77%);
        border-radius: 0;
        text-transform: uppercase;
    }
    .inputFileSendBtn{
        position: absolute;
        right: 1.2em;
        /* top: 0em; */
        border: none;
        box-shadow: -2px 5px 5px #555!important;
    }
</style>